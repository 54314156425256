.App {
  position: relative;
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: url("../brick_bg.jpg");
  background-size: cover;
}

.App-nav-menu {
  width: 25%;
  display: grid;
  padding-left: 2%;
  grid-template-areas: 
  'l'
  'n'
  'n'
  's';
}

.App-logo {
  grid-area: l;
  max-width: 160px;
  width: 75%;
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
}

.App-logo-static {
  display: none;
}

.Mobile-open-menu {
  display: none;
}

.App-nav-menu > nav {
  grid-area: n;
}

.App-social-links {
  grid-area: s;
}
.App-social-links > a > img {
  max-width: 75px;
  width: 30%;
  margin-right: 30px;
}

.App-social-links > .Link-table {
  width: 90%; 
  height: 100%;
  margin-bottom: -80px;
}

.App-nav-menu > nav {
  position: relative;
  max-width: 200px;
  width: 100%;
  
  /* --scale-factor: calc(100 * 0.01); */
}

.App-nav-menu > nav > a {
  position: relative;
  z-index: 99;
  display: block;
  margin-top: 20px;
  font-family: 'Source Code Pro', monospace;
  font-weight: 300;
  text-shadow: 5px 5px 4px #000000;

  text-decoration: none;
  color: antiquewhite;
  /* font-size: 2.4vw; */
  /* font-size: 100%; */
  font-size: 2em;
  animation: link-hover-out 500ms;
  /* animation-fill-mode: backwards; */
  transform-origin: left;
  /* transform: scale(1) translateX(0); */
  transform: scale(1);
  transition: color 0.5s;
}

.App-nav-menu > nav > a:hover {
  animation: link-hover 500ms;
  font-weight: 500;
  /* transform: scale(1.5) translateX(14%); */
  transform: scale(var(--scale-factor));
}
.App-nav-menu > nav > a.active:hover {
  animation: none;
  /* transform: scale(1.5) translateX(14%); */
  transform: scale(var(--scale-factor));
}
.App-nav-menu > nav > a.active {
  transition: color 0.5s;
  font-weight: 500;
  color: #2c1104;
  animation: none;
  /* transform: scale(1.5) translateX(14%); */
  transform: scale(var(--scale-factor));
}

@keyframes link-hover {
  0%{
    transform: scale(1);
  }
  100%{
    /* transform: scale(1.5) translateX(14%); */
    transform: scale(var(--scale-factor));
  }
}

@keyframes link-hover-out {
  0%{
    /* transform: scale(1.5) translateX(14%); */
    transform: scale(var(--scale-factor));
  }
  100%{
    transform: scale(1) translateX(0);
  }
}


.App-content-container {
  position: relative;
  margin-right: 5%;
  width: 75%;
  /* background: #141414dc; */
  background: #fcf2e6b2;
  color: black;
  box-shadow: 5px 10px 15px 10px #000000e7;
  overflow: auto;
}

.Page-content {
  position: relative;
  /* padding: 10px; */
}

.Page-content > h1 {
  position: relative;
  margin-left: 30px;
}

button.close {
  background: darkred;
  font-size: 1.3em;
  padding: 10px;
  border-radius: 100px;
}


button.styled-button-dark {
  background: #250a01e1;
  border-radius: 10px;
  /* color: #250a01e1; */
  color: antiquewhite;
  font-family: 'Source-Code-Pro', monospace;
  font-weight: 600;
  font-size: 0.8em;
  text-align: center;
  cursor: pointer;
}
button.styled-button-light {
  background: antiquewhite;
  border-radius: 10px;
  color: #250a01e1;
  font-family: 'Source-Code-Pro', monospace;
  font-weight: 600;
  font-size: 0.8em;
  text-align: center;
  cursor: pointer;
}