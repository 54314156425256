.Blogs-container {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  overflow-x: hidden;
  /* height: 100vh; */
  /* commenting out the above causes the adjustScrollToTop to not work anymore. */
  height: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}
#mediumLinkButton {
  display: block;
  position: absolute;
  top: -5px;
  right: 30px;
}
#mediumLinkButton > button > img {
  display: block;
  height: 20px;
  margin: 0 auto;
}
@media only screen and (max-width: 450px) {
  #mediumLinkButton {
    margin: 10px;
    margin-left: 30px;
    position: relative;
    top: unset;
    right: unset;
  }
}

@keyframes fadeIn {
  from{
    opacity: 0;
    transform: scale(0.3);
  }
  to{
    opacity: 1;
    transform: scale(1);
  }
}

/* Blog thumbnails: */
.Blogs-thumbnail {
  background: #250a01;
  overflow: hidden;
  margin: 10px;
  max-width: 275px;
  max-height: 250px;
  min-width: 275px;
  min-height: 250px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  align-self: center;
  justify-self: center;
  padding: 20px;
  border-radius: 50px;
  /* -webkit-box-shadow: inset 0px 0px 15px -6px #ffffff;  */
  /* box-shadow: inset 0px 0px 15px -6px #ffffff; */
  box-shadow: 5px 13px 15px 8px #000000da;
  
  transition: width 0.5s, height 0.5s;
  cursor: pointer;
  display: flex;

  animation: fadeIn 700ms ease-out;
  animation-fill-mode: backwards;
  animation-delay: calc(var(--animation-order) * 100ms);
}

.Blogs-thumbnail > .info-wrapper {
  justify-self: center;
  align-self: center;
}

.Blogs-thumbnail > .info-wrapper > h2 {
  /* width: 90%; */
  margin: auto;
  padding: 5px 0;
  font-size: 1.1em;
  text-align: center;
  background-color: rgba(250, 235, 215, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 5px;
}
.Blogs-thumbnail > .info-wrapper > p {
  padding: 3px;
  font-size: 0.9em;
  background-color: rgba(250, 235, 215, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 5px;
}


/* Fullscreen post */
.Blogs-post {
  z-index: 2;
  width: 100%;
  padding: 7%;
  /* background: #fcf2e6b2; */
  position: absolute;
  top: 0px;
  left: 0px;
  animation: animate-blog-in 500ms ease-out;
  -webkit-animation: animate-blog-in 500ms ease-out;
  overflow: auto;
  height: 100%;
  background: #250a01e1;
  -webkit-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  color: antiquewhite;
}

.Blogs-post > img {
  max-height: 300px;
  width: 100%;
}

@keyframes animate-blog-in {
  0%{transform: translateX(100%)}
  100%{transform: translateX(0%)}
}

@keyframes animate-close-ani {
  0%{transform: translateX(0%);}
  100%{transform: translateX(100%);}
}

.Blogs-post.animate-close {
  animation: animate-close-ani 500ms ease-out;
}

.Blogs-post article > figure > img {
  max-width: 100%;
}

@media only screen and (max-width: 875px) {
  .Blogs-container {
    grid-template-columns: 1fr;
    gap: 2.5rem;
  }
}

@media only screen and (max-width: 400px) {
  .Blogs-thumbnail {
    /* width: 85%;
    height: 100%; */
    /* width: 100%; */
    /* max-width: 200px;
    max-height: 175px; */
    width: 85%;
    height: 100%;
    max-width: unset;
    max-height: unset;
    min-width: unset;
    min-height: unset;
  }
}