@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Source+Code+Pro:ital,wght@0,300;0,400;1,300&display=swap');
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4 {
  font-family: 'Source Code Pro', monospace;
  line-height: 1em;
  font-stretch: condensed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  line-height: 1.3em;
}

@media only screen and (max-width: 400px) {
  h1, h2, h3, h4 {
    line-height: 1em;
    font-stretch: unset;
  }
}