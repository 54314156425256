.Projects-container {
  position: relative;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

@keyframes fadeIn {
  from{
    opacity: 0;
    transform: scale(0.3);
  }
  to{
    opacity: 1;
    transform: scale(1);
  }
}


/* Project app squares */

.Project-app-div {
  position: relative;
  max-width: 315px;
  max-height: 315px;
  min-width: 315px;
  min-height: 315px;
  width: 100%;
  height: 100%;
  margin: 1%;
  z-index: 1;
  cursor: pointer;

  justify-self: center;
  align-self: center;

  animation: fadeIn 700ms ease-out;
  animation-fill-mode: backwards;
  animation-delay: calc(var(--animation-order) * 200ms);
}
.Project-app-div > img {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
}

/* Open project:  */

@keyframes animate-opening {
  0%{
    transform: scale(0);
  }
  25%{

  }
  100%{
    transform: scale(1);
  }
}

.Project-details {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 5%;
  z-index: 2;
  background: #250a01e1;
  -webkit-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  width: 100%;
  min-height: 100%;
  
  animation: animate-opening 500ms;
}

.Project-details > button.close {
  position: absolute;
  top: 10px;
  left: 10px;
}

.Project-details > img {
  width: 225px;
  height: 225px;
}

.Project-details > h2 {
  color: antiquewhite;
}

.Project-details > p {
  color: antiquewhite;
  white-space: pre-wrap;
}

.Project-details > .links {
  display: flex;
  flex-wrap: wrap;
}

.Project-details > .links button {
  background: antiquewhite;
  border-radius: 10px;
  color: #250a01e1;
  font-family: 'Source-Code-Pro', monospace;
  font-weight: 400;
  font-size: 0.8em;
  text-align: center;
  height: 30px;
  padding: 1px 6px 1px 6px;
}

.Project-details > .links > button.demo {
  margin-left: 10px;
}

.Project-details > .links > button.demo:disabled {
  filter: grayscale(1);
  filter: brightness(0.2);
  cursor: wait;
}

.Project-details > .links > .repo > button > span {
  vertical-align: middle;
}

.Project-details > .links > .repo > button > img {
  vertical-align: middle;
  height: 100%;
}

.Project-details > #screenshots {
  margin-top: 30px;
  width: 100%;
  color: antiquewhite;
}

.Project-details > #screenshots > .screenshots-container {
  display: flex;
  width: 100%;
  /* NOTE: co'ed line below to fix horiz scroll issue. Not sure why this was implnt'ed, but in preliminary tests, no side-effects found when removed... */
  /* max-height: 500px; */
  overflow-x: auto;
}

.Project-details > #screenshots > .screenshots-container > figure {
  width: 98%;
  height: 98%;
  margin: 0px;
  margin-left: 1%;
  margin-right: 30px;
  flex-shrink: 0;
  background: antiquewhite;
  padding: 10px;
  box-shadow: 1px 3px 7px 2px black;
}

.Project-details > #screenshots > .screenshots-container > figure:last-child {
  margin-right: 0px;
}

.Project-details > #screenshots > .screenshots-container > figure > img {
  max-width: 100%;
  max-height: 100%;
;
}
.Project-details > #screenshots > .screenshots-container > figure > figcaption {
  text-align: center;
  color: #250a01e1;
}

@keyframes animate-closing {
  0%{
    transform: scale(1);
  }
  100%{
    transform: scale(0);
  }
}

.Project-details.closing {
  animation: animate-closing 500ms;
}

@media only screen and (max-width: 900px) {
  .Projects-container {

    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 400px) {
  .Project-app-div {

    width: 90%;
    height: 95%;
    max-width: unset;
    max-height: unset;
    min-width: unset;
    min-height: unset;
    /* height: 90%; */
  }
}

.Project-app-div > .icon-shadow {
  position: absolute;
  z-index: 1;
  top: 5.6%;
  left: 5.6%;
  border-radius: 75px;
  width: 88%;
  height: 88%;
  margin: auto;
  box-shadow: 7px 15px 15px 10px #000000da;
}

.Projects-demo-load-popup {
  position: absolute;
  top: 10%;
  left: 25%;
  border-radius: 25px;
  background: #fcf2e6b2;
  width: 50%;
  min-width: 200px;
  margin: auto;
  padding: 10px;
  -webkit-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 4px 7px 8px 5px #000000da;
}

.Projects-demo-load-popup > h2 {
  padding-top: 50px;
  /* color: antiquewhite; */
  color: #250a01e1;
}

.Projects-demo-load-popup > a {
  float: right;
  cursor: pointer;
}

.Projects-demo-load-popup > a:disabled {
  cursor: not-allowed;
}

.Projects-demo-load-popup > a > button {
  /* background: antiquewhite; */
  background: #250a01e1;
  border-radius: 10px;
  /* color: #250a01e1; */
  color: antiquewhite;
  font-family: 'Source-Code-Pro', monospace;
  font-weight: 600;
  font-size: 0.8em;
  text-align: center;
  cursor: pointer;
}

.Projects-demo-load-popup > a > button:disabled {
  filter: brightness(0.3);
  cursor: not-allowed;
}

.Projects-demo-load-popup > svg {
  width: 70%;
  height: 70%;

  max-width: 500px;
  max-height: 600px;
}

.Projects-demo-load-popup > svg #CoffeeBrew {
  animation: animateBrewIn 7s;
  opacity: 1;
}

@keyframes animateBrewIn {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}