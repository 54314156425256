@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Source+Code+Pro:ital,wght@0,300;0,400;1,300&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4 {
  font-family: 'Source Code Pro', monospace;
  line-height: 1em;
  font-stretch: condensed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  line-height: 1.3em;
}

@media only screen and (max-width: 400px) {
  h1, h2, h3, h4 {
    line-height: 1em;
    font-stretch: unset;
  }
}
.App {
  position: relative;
  display: -webkit-flex;
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: url(/static/media/brick_bg.590adf10.jpg);
  background-size: cover;
}

.App-nav-menu {
  width: 25%;
  display: grid;
  padding-left: 2%;
  grid-template-areas: 
  'l'
  'n'
  'n'
  's';
}

.App-logo {
  grid-area: l;
  max-width: 160px;
  width: 75%;
  -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
          filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
}

.App-logo-static {
  display: none;
}

.Mobile-open-menu {
  display: none;
}

.App-nav-menu > nav {
  grid-area: n;
}

.App-social-links {
  grid-area: s;
}
.App-social-links > a > img {
  max-width: 75px;
  width: 30%;
  margin-right: 30px;
}

.App-social-links > .Link-table {
  width: 90%; 
  height: 100%;
  margin-bottom: -80px;
}

.App-nav-menu > nav {
  position: relative;
  max-width: 200px;
  width: 100%;
  
  /* --scale-factor: calc(100 * 0.01); */
}

.App-nav-menu > nav > a {
  position: relative;
  z-index: 99;
  display: block;
  margin-top: 20px;
  font-family: 'Source Code Pro', monospace;
  font-weight: 300;
  text-shadow: 5px 5px 4px #000000;

  text-decoration: none;
  color: antiquewhite;
  /* font-size: 2.4vw; */
  /* font-size: 100%; */
  font-size: 2em;
  -webkit-animation: link-hover-out 500ms;
          animation: link-hover-out 500ms;
  /* animation-fill-mode: backwards; */
  -webkit-transform-origin: left;
          transform-origin: left;
  /* transform: scale(1) translateX(0); */
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: color 0.5s;
}

.App-nav-menu > nav > a:hover {
  -webkit-animation: link-hover 500ms;
          animation: link-hover 500ms;
  font-weight: 500;
  /* transform: scale(1.5) translateX(14%); */
  -webkit-transform: scale(var(--scale-factor));
          transform: scale(var(--scale-factor));
}
.App-nav-menu > nav > a.active:hover {
  -webkit-animation: none;
          animation: none;
  /* transform: scale(1.5) translateX(14%); */
  -webkit-transform: scale(var(--scale-factor));
          transform: scale(var(--scale-factor));
}
.App-nav-menu > nav > a.active {
  transition: color 0.5s;
  font-weight: 500;
  color: #2c1104;
  -webkit-animation: none;
          animation: none;
  /* transform: scale(1.5) translateX(14%); */
  -webkit-transform: scale(var(--scale-factor));
          transform: scale(var(--scale-factor));
}

@-webkit-keyframes link-hover {
  0%{
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100%{
    /* transform: scale(1.5) translateX(14%); */
    -webkit-transform: scale(var(--scale-factor));
            transform: scale(var(--scale-factor));
  }
}

@keyframes link-hover {
  0%{
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100%{
    /* transform: scale(1.5) translateX(14%); */
    -webkit-transform: scale(var(--scale-factor));
            transform: scale(var(--scale-factor));
  }
}

@-webkit-keyframes link-hover-out {
  0%{
    /* transform: scale(1.5) translateX(14%); */
    -webkit-transform: scale(var(--scale-factor));
            transform: scale(var(--scale-factor));
  }
  100%{
    -webkit-transform: scale(1) translateX(0);
            transform: scale(1) translateX(0);
  }
}

@keyframes link-hover-out {
  0%{
    /* transform: scale(1.5) translateX(14%); */
    -webkit-transform: scale(var(--scale-factor));
            transform: scale(var(--scale-factor));
  }
  100%{
    -webkit-transform: scale(1) translateX(0);
            transform: scale(1) translateX(0);
  }
}


.App-content-container {
  position: relative;
  margin-right: 5%;
  width: 75%;
  /* background: #141414dc; */
  background: #fcf2e6b2;
  color: black;
  box-shadow: 5px 10px 15px 10px #000000e7;
  overflow: auto;
}

.Page-content {
  position: relative;
  /* padding: 10px; */
}

.Page-content > h1 {
  position: relative;
  margin-left: 30px;
}

button.close {
  background: darkred;
  font-size: 1.3em;
  padding: 10px;
  border-radius: 100px;
}


button.styled-button-dark {
  background: #250a01e1;
  border-radius: 10px;
  /* color: #250a01e1; */
  color: antiquewhite;
  font-family: 'Source-Code-Pro', monospace;
  font-weight: 600;
  font-size: 0.8em;
  text-align: center;
  cursor: pointer;
}
button.styled-button-light {
  background: antiquewhite;
  border-radius: 10px;
  color: #250a01e1;
  font-family: 'Source-Code-Pro', monospace;
  font-weight: 600;
  font-size: 0.8em;
  text-align: center;
  cursor: pointer;
}
@media only screen and (max-width: 800px) {
  .App-nav-menu {
    position: absolute;
    z-index: 99;
    width: 50%;
    height: 100vh;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    top: 0px;
    left: 0px;
    background-color: rgba(250, 235, 215, 0.863);
    -webkit-backdrop-filter: blur(7px);
    -moz-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    box-shadow: 5px 13px 15px 8px #000000da;
    border-radius: 0px 15px 15px 0px;
    -webkit-animation: closeMenu 500ms ease-in-out;
            animation: closeMenu 500ms ease-in-out;
  }
  
  .App-nav-menu.opened {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation: openMenu 500ms ease-in-out;
            animation: openMenu 500ms ease-in-out;
  }

  @-webkit-keyframes openMenu {
    from {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    to {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes openMenu {
    from {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    to {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes closeMenu {
    from {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    to {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
  }

  @keyframes closeMenu {
    from {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    to {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
  }

  .App-nav-menu.opened > nav {
    display: block;
  }

  .App-nav-menu > nav {
    display: none;
  }

  .Mobile-open-menu {
    display: block;
    position: absolute;
    top: 35%;
    left: 100%;
    width: 25px;
    height: 200px;
    padding-left: 4px;
    border: 1px solid antiquewhite;
    background: #2c1104;
    border-radius: 0px 10px 10px 0px;
    padding-top: 50px;
    box-shadow: 8px 10px 10px 2px #000000da;
  }

  .Mobile-open-menu > span {
    word-wrap: break-word;
    color: antiquewhite;
  }

  .App-logo-static {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    max-width: 120px;
    width: 75%;
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  }

  .App-content-container {
    width: 90%;
    margin-left: 13%;
  }
}
.sun-box-shadow {
  box-shadow: 5px 10px 15px 10px #000000e7;
}

.sun-text-shadow {
  text-shadow: 5px 5px 4px #000000;
}

.blur-backdrop {
  -webkit-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
:root {
  --color-calendar-graph-day-bg: #dddbdb;
  --color-calendar-graph-day-L1-bg: #39dd34;
  --color-calendar-graph-day-L2-bg: #45a045;
  --color-calendar-graph-day-L3-bg: #047526;
  --color-calendar-graph-day-L4-bg: #0a4208;
}

rect.ContributionCalendar-day[data-level='0'] {
    fill: #dddbdb;
    fill: var(--color-calendar-graph-day-bg);
}

rect.ContributionCalendar-day[data-level='1'] {
    fill: #39dd34;
    fill: var(--color-calendar-graph-day-L1-bg);
}

rect.ContributionCalendar-day[data-level='2'] {
    fill: #45a045;
    fill: var(--color-calendar-graph-day-L2-bg);
}

rect.ContributionCalendar-day[data-level='3'] {
    fill: #047526;
    fill: var(--color-calendar-graph-day-L3-bg);
}

rect.ContributionCalendar-day[data-level='4'] {
    fill: #0a4208;
    fill: var(--color-calendar-graph-day-L4-bg);
}

.calendar .width-full > .float-left {
    display: none;
}

.calendar {
    font-family: Helvetica, arial;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    min-height: 243px;
    text-align: center;
    margin: 0 auto;
}

.calendar-graph text.wday,
.calendar-graph text.month {
    font-size: 10px;
    fill: #aaa;
}

.contrib-legend {
    text-align: right;
    padding: 0 14px 10px 0;
    display: inline-block;
    float: right;
}

.contrib-legend .legend {
    display: inline-block;
    list-style: none;
    margin: 0 5px;
    position: relative;
    bottom: -1px;
    padding: 0;
}

.contrib-legend .legend li {
    display: inline-block;
    width: 10px;
    height: 10px;
}

.text-small {
    font-size: 12px;
    color: #767676;
}

.calendar-graph {
    padding: 5px 0 0;
    text-align: center;
}

.contrib-column {
    padding: 15px 0;
    text-align: center;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    font-size: 11px;
}

.contrib-column-first {
    border-left: 0;
}

.table-column {
    display: table-cell;
    width: 1%;
    padding-right: 10px;
    padding-left: 10px;
    vertical-align: top;
}

.contrib-number {
    font-weight: 300;
    line-height: 1.3em;
    font-size: 24px;
    display: block;
    color: #333;
}

.calendar img.spinner {
    width: 70px;
    margin-top: 50px;
    min-height: 70px;
}

.monospace {
    text-align: center;
    color: #000;
    font-family: monospace;
}

.monospace a {
    color: #1D75AB;
    text-decoration: none;
}

.contrib-footer {
    font-size: 11px;
    padding: 0 10px 12px;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    height: 26px;
}

.left.text-muted {
    float: left;
    margin-left: 9px;
    color: #767676;
}
.left.text-muted a {
    color: #4078c0;
    text-decoration: none;
}
.left.text-muted a:hover,
.monospace a:hover {
    text-decoration: underline;
}

h2.f4.text-normal.mb-3 {
    display: none;
}

.float-left.text-gray {
    float: left;
}
#user-activity-overview{
    display:none;
}

.day-tooltip {
    white-space: nowrap;
    position: absolute;
    z-index: 99999;
    padding: 10px;
    font-size: 12px;
    color: #959da5;
    text-align: center;
    background: rgba(0,0,0,.85);
    border-radius: 3px;
    display: none;
    pointer-events: none;
}
.day-tooltip strong {
    color: #dfe2e5;
}
.day-tooltip.is-visible {
    display: block;
}
.day-tooltip:after {
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 5px;
    height: 5px;
    box-sizing: border-box;
    margin: 0 0 0 -5px;
    content: " ";
    border: 5px solid transparent;
    border-top-color: rgba(0,0,0,.85)
}

text.ContributionCalendar-label {
    fill: #ccc;
    font-size: 11px;
}
.Page-about {
  margin: 0 auto;
  margin-top: 40px;
  width: 90%;
}

.Page-about {
  display: grid;
  grid-template-areas: 
  'p ih2 ih2'
  'p i i'
  'd i i'
  'c c c'
  'sk sk sk'
  'sk sk sk';
}

.Page-about em {
  font-family: "Source Code Pro";
  font-weight: 600;
}

.Page-about > .my-face {
  /* height: 20%; */
  width: 100%;
  border-radius: 15px;
  grid-area: p;
  -webkit-animation: fadeIn 700ms ease-out;
          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(1 * 200ms);
          animation-delay: calc(1 * 200ms);
}

.Page-about > h2 {
  /* width: 75%; */
  /* padding-right: 10px; */
  padding-left: 15px;
  display: block;
  grid-area: ih2;
  font-size: 1.7em;

  -webkit-animation: fadeIn 700ms ease-out;

          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(1 * 200ms);
          animation-delay: calc(1 * 200ms);
}

.Page-about > p.intro {
  grid-area: i;
  font-size: 1.1em;
  text-align: center;
  padding-top: 25px;
  padding-left: 15px;

  -webkit-animation: fadeIn 700ms ease-out;

          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(2 * 200ms);
          animation-delay: calc(2 * 200ms);
}

div.calendar-container {
  grid-area: c;
  width: 95%;
  margin-bottom: 20px;
  margin: 0 auto;

  -webkit-animation: fadeIn 700ms ease-out;

          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(3 * 200ms);
          animation-delay: calc(3 * 200ms);
}

div.calendar-container > p {
  padding: 10px 0;
  text-align: center;
  width: 80%;
  margin: auto;
}

a.calendar {
  border: none;
  text-decoration: none;
  color: #2c1104;
}

.calendar-container > a.calendar > div > div > div > svg > g > text.ContributionCalendar-label {
  fill: #2c1104;
}

.Page-about > #diploma {
  grid-area: d;
  align-self: center;

  -webkit-animation: fadeIn 700ms ease-out;

          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(2.3 * 200ms);
          animation-delay: calc(2.3 * 200ms);
  padding: 15px;
}
.Page-about > #diploma > figure {
  margin: 0px;
  padding: 10px;
  background: #2c1104d7;
  border: solid 3px #2c1104;
  /* border-radius: 10px; */
  box-shadow: 3px 3px 10px 2px #000000e7;
  -webkit-transform: rotate(-4deg);
          transform: rotate(-4deg);
}
.Page-about > #diploma > figure > img {
  width: 100%;
  display: block;
  box-shadow: inset 5px 5px 10px 10px #3b3b3be7;
}
.Page-about > #diploma > figure > figcaption {
  margin-top: 5px;
  font-size: 0.8em;
  text-align: center;
  color: antiquewhite;
}
.Page-about > #diploma > figure > figcaption > a {
  color: antiquewhite;
}

.Page-about > section.knawledge {
  /* width: 100%; */
  /* height: 100%; */
  /* margin-top: 50px; */
  grid-area: sk;
  display: grid;
  grid-template-areas: 
  't t t'
  'ill ill .'
  '. ilt ilt'
  'kd kd kd'
  'kd kd kd';
}

.Page-about > section > h2 {
  display: inline;
  width: 100%;
  grid-area: t;

  -webkit-animation: fadeIn 700ms ease-out;

          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(3 * 200ms);
          animation-delay: calc(3 * 200ms);
}

.Page-about > section > .icon-list {
  /* height: 70px; */
  /* width: 55%; */
  height: 100%;
  border: 3px solid #2c1104;
  border-radius: 15px;
  background-color: #2c1104d7;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;

  -webkit-animation: fadeIn 700ms ease-out;

          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(4 * 200ms);
          animation-delay: calc(4 * 200ms);
}

.Page-about > section > .knawledge-desc {
  -webkit-animation: fadeIn 700ms ease-out;
          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(5 * 200ms);
          animation-delay: calc(5 * 200ms);
}

.Page-about > section > .icon-list img {
  height: 70px;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
}

.Page-about > section > .icon-list.langs {
  /* height: 70px; */
  /* width: 55%; */
  grid-area: ill;
  border-bottom-right-radius: 0px;
}

.Page-about > section > .icon-list.techs {
  /* float: right; */
  grid-area: ilt;
  margin-top: -3px;
  border-top-left-radius: 0px;
}

.Page-about > section > .knawledge-desc {
  grid-area: kd;
}

.Page-about > section > .icon-list > img:hover {
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.Page-about > section > .icon-list > img.active {
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
  /* filter: sepia(1); */
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  -webkit-animation: animateActiveIcon 1.5s infinite;
          animation: animateActiveIcon 1.5s infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

.Page-about-desc-project-icons > a > img {
  width: 200px;
}

/* Animations */

@-webkit-keyframes animateActiveIcon {
  from{
    -webkit-filter: sepia(0);
            filter: sepia(0);
  }
  to{
    -webkit-filter: sepia(0.8);
            filter: sepia(0.8);
  }
}

@keyframes animateActiveIcon {
  from{
    -webkit-filter: sepia(0);
            filter: sepia(0);
  }
  to{
    -webkit-filter: sepia(0.8);
            filter: sepia(0.8);
  }
}

@-webkit-keyframes fadeIn {
  from{
    opacity: 0;
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  to{
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes fadeIn {
  from{
    opacity: 0;
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  to{
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/* Media queries */
@media only screen and (max-width: 500px) {
  .Page-about {
    grid-template-areas: 
    'p p .'
    'ih2 ih2 ih2'
    'i i i'
    'd d d'
    'c c c'
    'sk sk sk'
    'sk sk sk';
  }

  .Page-about > section > .icon-list img {
    height: 50px;
  }
}
.Blogs-container {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  overflow-x: hidden;
  /* height: 100vh; */
  /* commenting out the above causes the adjustScrollToTop to not work anymore. */
  height: 100%;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}
#mediumLinkButton {
  display: block;
  position: absolute;
  top: -5px;
  right: 30px;
}
#mediumLinkButton > button > img {
  display: block;
  height: 20px;
  margin: 0 auto;
}
@media only screen and (max-width: 450px) {
  #mediumLinkButton {
    margin: 10px;
    margin-left: 30px;
    position: relative;
    top: unset;
    right: unset;
  }
}

@-webkit-keyframes fadeIn {
  from{
    opacity: 0;
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  to{
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes fadeIn {
  from{
    opacity: 0;
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  to{
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/* Blog thumbnails: */
.Blogs-thumbnail {
  background: #250a01;
  overflow: hidden;
  margin: 10px;
  max-width: 275px;
  max-height: 250px;
  min-width: 275px;
  min-height: 250px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  -webkit-align-self: center;
          align-self: center;
  justify-self: center;
  padding: 20px;
  border-radius: 50px;
  /* -webkit-box-shadow: inset 0px 0px 15px -6px #ffffff;  */
  /* box-shadow: inset 0px 0px 15px -6px #ffffff; */
  box-shadow: 5px 13px 15px 8px #000000da;
  
  transition: width 0.5s, height 0.5s;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;

  -webkit-animation: fadeIn 700ms ease-out;

          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(var(--animation-order) * 100ms);
          animation-delay: calc(var(--animation-order) * 100ms);
}

.Blogs-thumbnail > .info-wrapper {
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
}

.Blogs-thumbnail > .info-wrapper > h2 {
  /* width: 90%; */
  margin: auto;
  padding: 5px 0;
  font-size: 1.1em;
  text-align: center;
  background-color: rgba(250, 235, 215, 0.5);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 5px;
}
.Blogs-thumbnail > .info-wrapper > p {
  padding: 3px;
  font-size: 0.9em;
  background-color: rgba(250, 235, 215, 0.5);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 5px;
}


/* Fullscreen post */
.Blogs-post {
  z-index: 2;
  width: 100%;
  padding: 7%;
  /* background: #fcf2e6b2; */
  position: absolute;
  top: 0px;
  left: 0px;
  animation: animate-blog-in 500ms ease-out;
  -webkit-animation: animate-blog-in 500ms ease-out;
  overflow: auto;
  height: 100%;
  background: #250a01e1;
  -webkit-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  color: antiquewhite;
}

.Blogs-post > img {
  max-height: 300px;
  width: 100%;
}

@-webkit-keyframes animate-blog-in {
  0%{-webkit-transform: translateX(100%);transform: translateX(100%)}
  100%{-webkit-transform: translateX(0%);transform: translateX(0%)}
}

@keyframes animate-blog-in {
  0%{-webkit-transform: translateX(100%);transform: translateX(100%)}
  100%{-webkit-transform: translateX(0%);transform: translateX(0%)}
}

@-webkit-keyframes animate-close-ani {
  0%{-webkit-transform: translateX(0%);transform: translateX(0%);}
  100%{-webkit-transform: translateX(100%);transform: translateX(100%);}
}

@keyframes animate-close-ani {
  0%{-webkit-transform: translateX(0%);transform: translateX(0%);}
  100%{-webkit-transform: translateX(100%);transform: translateX(100%);}
}

.Blogs-post.animate-close {
  -webkit-animation: animate-close-ani 500ms ease-out;
          animation: animate-close-ani 500ms ease-out;
}

.Blogs-post article > figure > img {
  max-width: 100%;
}

@media only screen and (max-width: 875px) {
  .Blogs-container {
    grid-template-columns: 1fr;
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }
}

@media only screen and (max-width: 400px) {
  .Blogs-thumbnail {
    /* width: 85%;
    height: 100%; */
    /* width: 100%; */
    /* max-width: 200px;
    max-height: 175px; */
    width: 85%;
    height: 100%;
    max-width: unset;
    max-height: unset;
    min-width: unset;
    min-height: unset;
  }
}
.Projects-container {
  position: relative;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  gap: 1rem;
}

@-webkit-keyframes fadeIn {
  from{
    opacity: 0;
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  to{
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes fadeIn {
  from{
    opacity: 0;
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  to{
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


/* Project app squares */

.Project-app-div {
  position: relative;
  max-width: 315px;
  max-height: 315px;
  min-width: 315px;
  min-height: 315px;
  width: 100%;
  height: 100%;
  margin: 1%;
  z-index: 1;
  cursor: pointer;

  justify-self: center;
  -webkit-align-self: center;
          align-self: center;

  -webkit-animation: fadeIn 700ms ease-out;

          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(var(--animation-order) * 200ms);
          animation-delay: calc(var(--animation-order) * 200ms);
}
.Project-app-div > img {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
}

/* Open project:  */

@-webkit-keyframes animate-opening {
  0%{
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  25%{

  }
  100%{
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes animate-opening {
  0%{
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  25%{

  }
  100%{
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.Project-details {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 5%;
  z-index: 2;
  background: #250a01e1;
  -webkit-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  width: 100%;
  min-height: 100%;
  
  -webkit-animation: animate-opening 500ms;
  
          animation: animate-opening 500ms;
}

.Project-details > button.close {
  position: absolute;
  top: 10px;
  left: 10px;
}

.Project-details > img {
  width: 225px;
  height: 225px;
}

.Project-details > h2 {
  color: antiquewhite;
}

.Project-details > p {
  color: antiquewhite;
  white-space: pre-wrap;
}

.Project-details > .links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Project-details > .links button {
  background: antiquewhite;
  border-radius: 10px;
  color: #250a01e1;
  font-family: 'Source-Code-Pro', monospace;
  font-weight: 400;
  font-size: 0.8em;
  text-align: center;
  height: 30px;
  padding: 1px 6px 1px 6px;
}

.Project-details > .links > button.demo {
  margin-left: 10px;
}

.Project-details > .links > button.demo:disabled {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  -webkit-filter: brightness(0.2);
          filter: brightness(0.2);
  cursor: wait;
}

.Project-details > .links > .repo > button > span {
  vertical-align: middle;
}

.Project-details > .links > .repo > button > img {
  vertical-align: middle;
  height: 100%;
}

.Project-details > #screenshots {
  margin-top: 30px;
  width: 100%;
  color: antiquewhite;
}

.Project-details > #screenshots > .screenshots-container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  /* NOTE: co'ed line below to fix horiz scroll issue. Not sure why this was implnt'ed, but in preliminary tests, no side-effects found when removed... */
  /* max-height: 500px; */
  overflow-x: auto;
}

.Project-details > #screenshots > .screenshots-container > figure {
  width: 98%;
  height: 98%;
  margin: 0px;
  margin-left: 1%;
  margin-right: 30px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  background: antiquewhite;
  padding: 10px;
  box-shadow: 1px 3px 7px 2px black;
}

.Project-details > #screenshots > .screenshots-container > figure:last-child {
  margin-right: 0px;
}

.Project-details > #screenshots > .screenshots-container > figure > img {
  max-width: 100%;
  max-height: 100%;
;
}
.Project-details > #screenshots > .screenshots-container > figure > figcaption {
  text-align: center;
  color: #250a01e1;
}

@-webkit-keyframes animate-closing {
  0%{
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100%{
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes animate-closing {
  0%{
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100%{
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

.Project-details.closing {
  -webkit-animation: animate-closing 500ms;
          animation: animate-closing 500ms;
}

@media only screen and (max-width: 900px) {
  .Projects-container {

    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 400px) {
  .Project-app-div {

    width: 90%;
    height: 95%;
    max-width: unset;
    max-height: unset;
    min-width: unset;
    min-height: unset;
    /* height: 90%; */
  }
}

.Project-app-div > .icon-shadow {
  position: absolute;
  z-index: 1;
  top: 5.6%;
  left: 5.6%;
  border-radius: 75px;
  width: 88%;
  height: 88%;
  margin: auto;
  box-shadow: 7px 15px 15px 10px #000000da;
}

.Projects-demo-load-popup {
  position: absolute;
  top: 10%;
  left: 25%;
  border-radius: 25px;
  background: #fcf2e6b2;
  width: 50%;
  min-width: 200px;
  margin: auto;
  padding: 10px;
  -webkit-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 4px 7px 8px 5px #000000da;
}

.Projects-demo-load-popup > h2 {
  padding-top: 50px;
  /* color: antiquewhite; */
  color: #250a01e1;
}

.Projects-demo-load-popup > a {
  float: right;
  cursor: pointer;
}

.Projects-demo-load-popup > a:disabled {
  cursor: not-allowed;
}

.Projects-demo-load-popup > a > button {
  /* background: antiquewhite; */
  background: #250a01e1;
  border-radius: 10px;
  /* color: #250a01e1; */
  color: antiquewhite;
  font-family: 'Source-Code-Pro', monospace;
  font-weight: 600;
  font-size: 0.8em;
  text-align: center;
  cursor: pointer;
}

.Projects-demo-load-popup > a > button:disabled {
  -webkit-filter: brightness(0.3);
          filter: brightness(0.3);
  cursor: not-allowed;
}

.Projects-demo-load-popup > svg {
  width: 70%;
  height: 70%;

  max-width: 500px;
  max-height: 600px;
}

.Projects-demo-load-popup > svg #CoffeeBrew {
  -webkit-animation: animateBrewIn 7s;
          animation: animateBrewIn 7s;
  opacity: 1;
}

@-webkit-keyframes animateBrewIn {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animateBrewIn {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.Page-resume > h1 {
  display: block;
  float: left;
}

#Download-resume {
  float: right;
  margin-right: 3%;
  margin-top: 15px;

  -webkit-animation: fadeIn 700ms ease-out;

          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(0.5 * 200ms);
          animation-delay: calc(0.5 * 200ms);
}
#Download-resume > button > img {
  width: 50px;
}
#Download-resume > button > span {
  display: block;
}

.resume-wrapper {
  padding: 10px 50px;
  float: left;
}

.resume-wrapper h2 {
  text-align: center;
  margin-top: 40px;
}

.resume-wrapper h2 + hr + p {
  text-align: center;
}
.resume-wrapper .split {
  width: 100%;
  display: inline-block;
}
.resume-wrapper .split > span.desc {
  float: left;
}
.resume-wrapper .split > h4 {
  float: left;
  margin: 0px;
}
.resume-wrapper .split > span {
  float: right;
}

.resume-wrapper > h1 {
  -webkit-animation: fadeIn 700ms ease-out;
          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(1 * 200ms);
          animation-delay: calc(1 * 200ms);
}

.resume-wrapper > #contact {
  -webkit-animation: fadeIn 700ms ease-out;
          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(2 * 200ms);
          animation-delay: calc(2 * 200ms);
}

.resume-wrapper > #intro {
  -webkit-animation: fadeIn 700ms ease-out;
          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(3 * 200ms);
          animation-delay: calc(3 * 200ms);
}

.resume-wrapper > #skills {
  -webkit-animation: fadeIn 700ms ease-out;
          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(4 * 200ms);
          animation-delay: calc(4 * 200ms);
}

.resume-wrapper > #projects {
  -webkit-animation: fadeIn 700ms ease-out;
          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(5 * 200ms);
          animation-delay: calc(5 * 200ms);
}

.resume-wrapper > #exp {
  -webkit-animation: fadeIn 700ms ease-out;
          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(6 * 200ms);
          animation-delay: calc(6 * 200ms);
}

.resume-wrapper > #knawledge {
  -webkit-animation: fadeIn 700ms ease-out;
          animation: fadeIn 700ms ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-delay: calc(7 * 200ms);
          animation-delay: calc(7 * 200ms);
}


@-webkit-keyframes fadeIn {
  from{
    opacity: 0;
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  to{
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


@keyframes fadeIn {
  from{
    opacity: 0;
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  to{
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
