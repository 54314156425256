.Page-about {
  margin: 0 auto;
  margin-top: 40px;
  width: 90%;
}

.Page-about {
  display: grid;
  grid-template-areas: 
  'p ih2 ih2'
  'p i i'
  'd i i'
  'c c c'
  'sk sk sk'
  'sk sk sk';
}

.Page-about em {
  font-family: "Source Code Pro";
  font-weight: 600;
}

.Page-about > .my-face {
  /* height: 20%; */
  width: 100%;
  border-radius: 15px;
  grid-area: p;
  animation: fadeIn 700ms ease-out;
  animation-fill-mode: backwards;
  animation-delay: calc(1 * 200ms);
}

.Page-about > h2 {
  /* width: 75%; */
  /* padding-right: 10px; */
  padding-left: 15px;
  display: block;
  grid-area: ih2;
  font-size: 1.7em;

  animation: fadeIn 700ms ease-out;
  animation-fill-mode: backwards;
  animation-delay: calc(1 * 200ms);
}

.Page-about > p.intro {
  grid-area: i;
  font-size: 1.1em;
  text-align: center;
  padding-top: 25px;
  padding-left: 15px;

  animation: fadeIn 700ms ease-out;
  animation-fill-mode: backwards;
  animation-delay: calc(2 * 200ms);
}

div.calendar-container {
  grid-area: c;
  width: 95%;
  margin-bottom: 20px;
  margin: 0 auto;

  animation: fadeIn 700ms ease-out;
  animation-fill-mode: backwards;
  animation-delay: calc(3 * 200ms);
}

div.calendar-container > p {
  padding: 10px 0;
  text-align: center;
  width: 80%;
  margin: auto;
}

a.calendar {
  border: none;
  text-decoration: none;
  color: #2c1104;
}

.calendar-container > a.calendar > div > div > div > svg > g > text.ContributionCalendar-label {
  fill: #2c1104;
}

.Page-about > #diploma {
  grid-area: d;
  align-self: center;

  animation: fadeIn 700ms ease-out;
  animation-fill-mode: backwards;
  animation-delay: calc(2.3 * 200ms);
  padding: 15px;
}
.Page-about > #diploma > figure {
  margin: 0px;
  padding: 10px;
  background: #2c1104d7;
  border: solid 3px #2c1104;
  /* border-radius: 10px; */
  box-shadow: 3px 3px 10px 2px #000000e7;
  transform: rotate(-4deg);
}
.Page-about > #diploma > figure > img {
  width: 100%;
  display: block;
  box-shadow: inset 5px 5px 10px 10px #3b3b3be7;
}
.Page-about > #diploma > figure > figcaption {
  margin-top: 5px;
  font-size: 0.8em;
  text-align: center;
  color: antiquewhite;
}
.Page-about > #diploma > figure > figcaption > a {
  color: antiquewhite;
}

.Page-about > section.knawledge {
  /* width: 100%; */
  /* height: 100%; */
  /* margin-top: 50px; */
  grid-area: sk;
  display: grid;
  grid-template-areas: 
  't t t'
  'ill ill .'
  '. ilt ilt'
  'kd kd kd'
  'kd kd kd';
}

.Page-about > section > h2 {
  display: inline;
  width: 100%;
  grid-area: t;

  animation: fadeIn 700ms ease-out;
  animation-fill-mode: backwards;
  animation-delay: calc(3 * 200ms);
}

.Page-about > section > .icon-list {
  /* height: 70px; */
  /* width: 55%; */
  height: 100%;
  border: 3px solid #2c1104;
  border-radius: 15px;
  background-color: #2c1104d7;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  animation: fadeIn 700ms ease-out;
  animation-fill-mode: backwards;
  animation-delay: calc(4 * 200ms);
}

.Page-about > section > .knawledge-desc {
  animation: fadeIn 700ms ease-out;
  animation-fill-mode: backwards;
  animation-delay: calc(5 * 200ms);
}

.Page-about > section > .icon-list img {
  height: 70px;
  transform: scale(1);
  transition: transform 500ms;
}

.Page-about > section > .icon-list.langs {
  /* height: 70px; */
  /* width: 55%; */
  grid-area: ill;
  border-bottom-right-radius: 0px;
}

.Page-about > section > .icon-list.techs {
  /* float: right; */
  grid-area: ilt;
  margin-top: -3px;
  border-top-left-radius: 0px;
}

.Page-about > section > .knawledge-desc {
  grid-area: kd;
}

.Page-about > section > .icon-list > img:hover {
  transition: transform 500ms;
  transform: scale(1.3);
}

.Page-about > section > .icon-list > img.active {
  transition: transform 500ms;
  /* filter: sepia(1); */
  transform: scale(1.3);
  animation: animateActiveIcon 1.5s infinite;
  animation-direction: alternate;
}

.Page-about-desc-project-icons > a > img {
  width: 200px;
}

/* Animations */

@keyframes animateActiveIcon {
  from{
    filter: sepia(0);
  }
  to{
    filter: sepia(0.8);
  }
}

@keyframes fadeIn {
  from{
    opacity: 0;
    transform: scale(0.3);
  }
  to{
    opacity: 1;
    transform: scale(1);
  }
}

/* Media queries */
@media only screen and (max-width: 500px) {
  .Page-about {
    grid-template-areas: 
    'p p .'
    'ih2 ih2 ih2'
    'i i i'
    'd d d'
    'c c c'
    'sk sk sk'
    'sk sk sk';
  }

  .Page-about > section > .icon-list img {
    height: 50px;
  }
}